import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerPageBannerWithTitleDescription from "../components/common/InnerPageBannerWithTitleDescription"
import InnerPageBodyWithToc from "../components/common/InnerPageBodyWithToc"
import { TermsAndConditionsContent } from "../Content/TermsAndConditions"

const TermsAndConditions = () => {
  return (
    <Layout>
      <Seo
        title="Terms And Conditions"
        description={`Read Synkli’s terms and conditions to understand the rules and agreements governing our services. Stay informed about your rights and responsibilities.`}
      />

      <InnerPageBannerWithTitleDescription
        title={TermsAndConditionsContent.title}
        description={TermsAndConditionsContent.description}
        extraDescription={TermsAndConditionsContent.extraDescription}
        extraDescriptionEC={`font-[600]`}
        image={{
          path: TermsAndConditionsContent.image.path,
          alt: TermsAndConditionsContent.image.alt,
        }}
        imageWrapperEC={`self-end hidden md:block`}
      />

      <div className="synkli-section--horizontal-space mt-10 md:mt-20 mb-10 md:mb-20">
        <hr />
      </div>

      <InnerPageBodyWithToc page={TermsAndConditionsContent} />
    </Layout>
  )
}

export default TermsAndConditions
